import React from "react";
import moment from "moment";
import loadable from "@loadable/component";
import { getType, imageSrcUrl, videoSrcUrl } from "../../common/helpers/media";

const Sidebar = loadable(() =>
  import("../../frontend/components/navigation/Sidebar")
);
const Breadcrumb = loadable(() =>
  import("../../frontend/components/Breadcrumb")
);

function SimpleHeader({ index, context = [] }) {
  if (!context.length) {
    return <></>;
  }

  function renderMedia(media) {
    switch (media ? getType(media.mimetype) : null) {
      case "image":
        return (
          <div
            className="uk-flex uk-flex-bottom uk-position-relative uk-background-cover"
            style={{ backgroundImage: `url(${imageSrcUrl(media)})` }}
          />
        );
      case "video":
        return (
          <div className="uk-cover-container">
            <video
              src={videoSrcUrl(media)}
              loop={media.loop || true}
              data-uk-video="autoplay: inview; automute: true"
              data-uk-cover=""
            />
          </div>
        );
      default:
        return (
          <div
            data-uk-height-viewport="offset-top: true"
            className="uk-flex uk-flex-bottom uk-position-relative uk-background-cover"
          />
        );
    }
  }

  return (
    <>
      <section className="uk-section-default uk-position-relative header">
        {index === 0 && <Sidebar />}
        {renderMedia(context[1])}
        {context[0].length > 0 && (
          <div data-uk-parallax="x:0,0; y:0,75; media:@l;">
            <div className="uk-position-bottom-left uk-position-large uk-width-expand uk-width-auto@s">
              <div className="uk-card uk-card-primary uk-card-body uk-card-large">
                {context[2] && (
                  <p>
                    {moment(context[2]).locale("de").format("dddd, DD.MM.YYYY")}
                  </p>
                )}
                <div
                  className="uk-width-auto uk-text-break"
                  dangerouslySetInnerHTML={{ __html: context[0] }}
                />
              </div>
            </div>
          </div>
        )}
      </section>
      {index === 0 && <Breadcrumb />}
    </>
  );
}

export default SimpleHeader;
